@import url('https://fonts.googleapis.com/css?family=DM+Serif+Display|Lora|PT+Serif&display=swap');
@import '_vars';

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'PT Serif', serif;
    font-weight: normal;
}

// IE11 fix for flex container with min-height
.header-wrapper {
    display: flex;
    flex-direction: column;
}

#hero {
    background-color: #005ba3;
    background-image: url('../img/hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 35%;
    display: flex;
    align-items: flex-end;
    min-height: 400px;
    max-height: 40vh;

    .container h1, .container .h2 {
        color: #fff;
        font-family: 'DM Serif Display', serif;
        margin: 0;
    }

    .container h1 {
        font-size: 5rem;
        line-height: 4.25rem;
        margin: 0;
        display: inline-block;
    }

    .container .h2 {
        padding-bottom: 8px;
        border-bottom: 5px solid #fff;
        display: block;
    }

    @include media-breakpoint-only(xs) {
        background-position-x: 65%;

        .container h1 {
            font-size: 4rem;
            line-height: 3.75rem;
        }
        .container .h2 {
            font-size: 1.5rem;
        }
    }
}

main {
    font-family: 'Lora', serif;
    margin-bottom: 150px;

    .expose {
        color: #205ba0;
    }

    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
        line-height: 140%;
        max-width: 1024px;
    }

    #services {
        > div {
            padding: 18px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        .service-icon {
            display: inline-block;
            padding: 16px;
            background: #f1d2d2;
            border-radius: 100%;
            margin-right: 15px;

            svg {
                width: 40px;
                height: 40px;
            }
        }

        span {
            font-size: 1.4rem;
            max-width: 80%;
        }
    }

    .contact {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin-bottom: 12px;
        min-width: 325px;

        svg {
            vertical-align: text-bottom;
            margin-right: 6px;
            width: 24px;
            height: 24px;
        }
    }
}
